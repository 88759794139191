<template>
  <span>
    <b-button
      @click="printKartuBerobat('kartu_berobat')"
      variant="info"
      :disabled="!data_puskesmas.id && !dataPasien.rm_id"
      >Kartu Berobat
    </b-button>
    <!-- PRINT KARTU BEROBAT -->
    <div id="kartu_berobat" style="display: none;">
      <section class="content-print paper-58">
        <div class="sheet custom-border-paper">
          <div v-if="isReady">
            <div class="w-100">
              <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 20%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 60%;vertical-align: middle;">
                            <h6 style="text-align: center;font-weight: bold;font-size:8pt">{{ data_puskesmas.nama_puskesmas }}</h6>
                            <h6 style="text-align: center;font-weight: normal;font-size: 6pt;">{{ data_puskesmas.alamat_puskesmas }}</h6>
                        </td>
                        <td style="width: 20%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
              </table>
              <div class="line"></div>
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td style="width: 14mm;font-size: 8pt;padding:0.5mm">No. RM</td>
                    <td style="width: 2.5mm;text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ dataPasien.no_rm }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">KK</td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ dataPasien.no_kk }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">Nama </td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ dataPasien.nama_lengkap }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">Tgl. Lahir</td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ $moment(dataPasien.tanggal_lahir).format('DD MMMM YYYY') }} 
                    <br> {{ dataPasien.umur }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">Alamat</td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ dataPasien.alamat_sekarang }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">Tgl. Kunjungan </td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">{{ $moment().format('DD-MM-YYYY') }}</td>
                  </tr>

                  <tr>
                    <td style="font-size: 8pt;padding:0.5mm">Kartu</td>
                    <td style="text-align: center;border-left: hidden;border-right: hidden;font-size: 8pt;padding:0.5mm">:</td>
                    <td style="font-size: 8pt;padding:0.5mm">[ nomor_kartu ]</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </span>
</template>

<script>
export default {
  props: [
    'isReady',
    'data_puskesmas',
    'dataPasien',
  ],
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    async printKartuBerobat(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          // '/style-paper-80mm.css'
          '/style-paper-58mm.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas'), // override the window title
      }
      // await this.$htmlToPaper(val, options);
      await this.$htmlToPaper(val, options);
    },
    
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>