<template>
  <b-modal
    id="modal-pendaftaran"
    size="xl"
    title="List Pendaftaran"
    header-bg-variant="success"
    header-text-variant="light"
    @show="openModal()"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row style="row-gap: 10px;">
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_urut">No. Urut</label>
              <b-form-input
                id="no_urut"
                v-model="dataForm.no_urut"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_rm">No. RM</label>
              <b-form-input
                id="no_rm"
                v-model="dataForm.no_rm"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="no_bpjs">No. BPJS</label>
              <b-form-input
                id="no_bpjs"
                v-model="dataForm.no_bpjs"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="6" lg="4">
              <label for="nama_lengkap">Nama Pasien</label>
              <b-form-input
                id="nama_lengkap"
                v-model="dataForm.nama_lengkap"
                @keydown.prevent.enter="getData()"
              ></b-form-input>
            </b-col>
            <b-col cols="4" sm="12" md="12" lg="4">
              <label for="poliklinik">Poliklinik</label>
              <multiselect
                id="poliklinik"
                v-model="dataForm.poliklinik"
                :options="listPoliklinik"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_poli"
                track-by="ms_poli_id"
                placeholder="-- Poliklinik --"
                size="sm"
              ></multiselect>
            </b-col>
            <b-col cols="2" sm="12" md="6" lg="2">
              <label for="tanggal_awal">Tanggal awal</label>
              <date-picker
                style="width: 100%"
                id="tanggal_awal"
                format="DD-MM-YYYY"
                show-hour
                show-minute
                timepicker 
                v-model="dataForm.tanggal_awal"
                @change="getData()"
              ></date-picker>
            </b-col>
            <b-col cols="2" sm="12" md="6" lg="2">
              <label for="tanggal_akhir">Tanggal akhir</label>
              <date-picker
                style="width: 100%"
                id="tanggal_akhir"
                format="DD-MM-YYYY"
                show-hour
                show-minute
                timepicker 
                v-model="dataForm.tanggal_akhir"
                @change="getData()"
              ></date-picker>
            </b-col>
          </b-row>
          <b-row align-h="between" align-v="center" class="mt-3 mb-4">
            <b-col cols="auto">
              <h6 class="p-0 m-0"><strong>Total pendaftaran hari ini : {{totalRows}}</strong></h6>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-button
                  class="mr-2"
                  @click="refresh()"
                  variant="danger"
                  >Reset
                </b-button>
                <b-button
                  class="mr-2"
                  @click="getData()"
                  variant="success"
                  >Cari
                </b-button>
                <b-button
                  @click="printCetak('cetak_pendaftaran')"
                  v-c-tooltip.hover.click="'Cetak List Pendaftaran'"
                  variant="info"
                  >Cetak List
                </b-button>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                responsive
                show-empty
                small
                bordered
                striped
                hover
                style="white-space:nowrap;"
                :busy="tableBusy"
              >
                <template #cell(actions)="item">
                <div class="d-flex flex-nowrap">
                  <b-button
                    variant="info"
                    v-c-tooltip.hover.click="'Detail Data'"
                    @click="$emit('pilihPendaftaran', item.item), $bvModal.hide('modal-pendaftaran')"
                    ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                  >
                  <b-button
                    v-if="!item.item.ms_status_pulang_id"
                    class="ml-1"
                    variant="danger"
                    v-c-tooltip.hover.click="'Hapus Pendaftaran'"
                    v-b-modal.modal-delete-pendaftaran
                    @click="is_data = item.item"
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  >
                </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-col md="5" offset-md="7">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-row>
    </b-container>

    <div style="position: relative; display: none" id="cetak_pendaftaran">
      <div class="w-100" style="margin-top: 5mm;">
        <h6 style="font-size: 16pt;font-weight: bold;text-align: center;">DAFTAR PASIEN</h6>
        <h6 style="font-size: 16pt;font-weight: bold;text-align: center;margin-top: 5mm;">{{ $moment().format('LL') }}</h6>
      </div>

      <div class="w-100" style="margin-top: 5mm;">
        <table border="1" class="custom-table">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>No.</th>
              <th>Nama</th>
              <th>Ruangan</th>
              <th>Asuransi</th>
              <th>No. BPJS</th>
              <th>Jenis Layanan</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in dataPrint" :key="item.no">
              <td>{{ item.nama_tanggal_daftar }}</td>
              <td class="text-center">{{ item.no }}</td>
              <td>{{ item.nama_lengkap }}</td>
              <td>{{ item.nama_poli }}</td>
              <td>{{ item.no_bpjs ? "BPJS" : "UMUM" }}</td>
              <td>{{ item.no_bpjs || '-' }}</td>
              <td class="text-center">{{ item.nama_jenis_layanan }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-pendaftaran')">
        Tutup
      </b-button>
    </template>
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getData()"
    />
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from 'moment'
import ModalDelete from './modal_delete.vue'
export default {
  components: {
    Multiselect,
    DatePicker,
    ModalDelete,
  },
  emits: ['pilihPendaftaran'],
  props: ['listPoliklinik'],
  data() {
    return {
      items: [],
      is_data: {},
      dataForm: {
        no_urut: null,
        no_rm: null,
        no_bpjs: null,
        nama_lengkap: null,
        poliklinik: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      // listPoliklinik: [{id: 1, nama_poliklinik: 'pilihan 1'}, {id: 2, nama_poliklinik: 'pilihan 2'}, {id: 3, nama_poliklinik: 'pilihan 3'}, ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
        {
          key: "no_urut",
          label: "No. Urut",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_rm",
          label: "No. RM",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_rm_lama",
          label: "No. RM Lama",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Nama Poli",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_jenis_layanan",
          label: "Jenis Layanan",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_bpjs",
          label: "No. BPJS",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_tanggal_daftar",
          label: "Tanggal",
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "no_telp",
          label: "Telepon",
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
      dataPrint: [],
    }
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
      // return this.$moment(this.$store.state.tanggal_sesi)
    },
  },
  watch: {
    'currentPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
    'perPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getData()
      }
    },
    'dataForm.kategori_pencarian'(newVar, oldVar){
      if(newVar != oldVar){
        this.dataForm.kata_kunci = null
      }
    },
  },
  methods: {
    async openModal(){
      const vm = this
      // console.log('buka modal')
      this.dataForm.tanggal_awal = new Date(this.tanggal_sesi)
      this.dataForm.tanggal_akhir = new Date(this.tanggal_sesi)
      // if(!vm.dataForm.tanggal_awal){
      //   vm.dataForm.tanggal_awal = new Date()
      //   // vm.dataForm.tanggal_awal.setHours(0)
      //   // vm.dataForm.tanggal_awal.setMinutes(0)
      //   // vm.dataForm.tanggal_awal.setSeconds(0)
      // }
      // if(!vm.dataForm.tanggal_akhir){
      //   vm.dataForm.tanggal_akhir = new Date()
      //   // vm.dataForm.tanggal_akhir.setHours(23)
      //   // vm.dataForm.tanggal_akhir.setMinutes(59)
      //   // vm.dataForm.tanggal_akhir.setSeconds(59)
      // }
      vm.getData()
      vm.getList()
    },
    async getList(){
      // const vm = this
    },
    async getData(){
      const vm = this
      // console.log('ini data form', vm.dataForm)
      vm.tableBusy = true
      const x = vm.dataForm
      //result
      let result = await vm.$axios.post('/pendaftaran/list', {
        ...x, 
        tanggal_awal: x.tanggal_awal ? vm.$moment(x.tanggal_awal).format('YYYY-MM-DD') : null,
        tanggal_akhir: x.tanggal_akhir ? vm.$moment(x.tanggal_akhir).format('YYYY-MM-DD') : null,
        ms_poli_id: x.poliklinik ? x.poliklinik.ms_poli_id : null,
        halaman: vm.currentPage - 1 + '', 
        jumlah : vm.perPage,
      })
      if (result.data.status == 200) {
        vm.items = result.data.data.map((x, idx) => {
          return {
            ...x,
            no: idx + 1 + (this.currentPage - 1) * this.perPage,
            nama_tanggal_daftar: moment(x.tanggal_daftar).format('LLLL'),
            nama_jenis_layanan: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, false, 'kdTkp', 'text'),
          }
        })
      }
      // console.log('ini pendaftaran', vm.items)
      vm.totalRows = parseInt(result.data.count)
      vm.tableBusy = false
    },
    refresh(){
      this.dataForm = {
        no_urut: null,
        no_rm: null,
        no_bpjs: null,
        nama_lengkap: null,
        poliklinik: null,
        tanggal_awal: null,
        tanggal_akhir: null,
      }
      this.dataForm.tanggal_awal = new Date()
      // this.dataForm.tanggal_awal.setHours(0)
      // this.dataForm.tanggal_awal.setMinutes(0)
      // this.dataForm.tanggal_awal.setSeconds(0)
      
      this.dataForm.tanggal_akhir = new Date()
      // this.dataForm.tanggal_akhir.setHours(23)
      // this.dataForm.tanggal_akhir.setMinutes(59)
      // this.dataForm.tanggal_akhir.setSeconds(59)
      this.getData()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    async getDataPrint() {
      const vm = this
      const x = vm.dataForm
      try {
        let res = await vm.$axios.post('/pendaftaran/list', {
          ...x,
          tanggal_awal: x.tanggal_awal ? vm.$moment(x.tanggal_awal).format('YYYY-MM-DD') : null,
          tanggal_akhir: x.tanggal_akhir ? vm.$moment(x.tanggal_akhir).format('YYYY-MM-DD') : null,
          ms_poli_id: x.poliklinik ? x.poliklinik.ms_poli_id : null,
        })
        if (res.data.status == 200) {
          vm.dataPrint = res.data.data.map((obj, idx) => {
            return {
              ...obj,
              no: idx + 1 + (vm.currentPage - 1) * vm.perPage,
              nama_tanggal_daftar: moment(obj.tanggal_daftar).format('LLLL'),
              nama_jenis_layanan: vm.$findKey(vm.$store.state.data_static.tkp, obj.kd_tkp, false, 'kdTkp', 'text'),
            }
          })
          return true
        }
      } catch (err) {
        console.log(err);
        return false
      }
    },
    async printCetak(val) {
      console.log("asd");
      let bool = await this.getDataPrint()
      if (bool) {
        this.$htmlToPaper(val);
      }
    },
  },
}
</script>