<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <!-- <pre>{{ dataPasien }}</pre> -->
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Pendaftaran
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            
            <b-button variant="primary  " @click="$bvModal.show('modal-pendaftaran')" :disabled="busy"> 
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                    List Pendaftaran
                </h6>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="m-3">
            <b-row>
              <b-col>
                <b-alert style="height:50px; font-size: 16px;" v-model="alertnya" variant="danger" dismissible>
                  <b>PASIEN BELUM ADA TANGGAL LAHIR / NIK. HARAP ISI DI REKAM MEDIS TERLEBIH DAHULU UNTUK MELANJUTKAN PENDAFTARAN!</b> 
                </b-alert>
              </b-col>
            </b-row>
            <b-row align-h="between" align-v="center" class="my-1" style="margin: 0px -8px;">
              
              <b-col cols="auto" class="m-0 p-0">
                <label for="input-no_rm">No. RM</label>
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      id="input-no_rm"
                      v-model="dataForm.no_rm"
                      trim
                      readonly
                      placeholder="Pilih Pasien"
                      :state="checkValidasi('rm_id').s"
                    ></b-form-input>
                    <b-form-input
                      v-if="dataPasien.no_rm_lama"
                      id="no_rm_lama"
                      v-model="dataPasien.no_rm_lama"
                      disabled
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        :disabled="!isReg() || busy"
                        @click="$bvModal.show('modal-search-pendaftaran')"
                        > <CIcon name="cil-list" />  Cari Pasien
                      </b-button>
                      <b-button
                        @click="refresh()"
                        variant="danger"
                        :disabled="busy"
                        >Reset
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                    <span class="text-danger">{{checkValidasi('rm_id').m}}</span>
                </b-form-group>
              </b-col>
              <b-col cols="auto" class="m-0 mt-3 p-0">
                <!-- <b-button variant="primary" class="mr-2" @click="$router.push('/registrasi/diagnosa')">
                  <h6 class="m-0 p-0 text-light">
                    Diagnosa
                  </h6>
                </b-button> -->
                <!-- <b-button variant="warning" class="mr-2">
                  <h6 class="m-0 p-0 text-light">
                    <b-icon icon="search"></b-icon> 
                    Booking
                  </h6>
                </b-button> -->
                
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="tanggal_daftar">Tanggal <span class="text-danger">*</span></label>
                <date-picker
                  style="width: 100%"
                  id="tanggal_daftar"
                  format="DD-MM-YYYY HH:mm:ss"
                  type="datetime"
                  v-model="dataForm.tanggal_daftar"
                  :state="checkValidasi('tanggal_daftar').s"
                ></date-picker>
                <span class="text-danger">{{checkValidasi('tanggal_daftar').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="kd_tkp">Jenis Layanan <span class="text-danger">*</span></label>
                <multiselect
                  id="kd_tkp"
                  v-model="dataForm.kd_tkp"
                  :options="$store.state.data_static.tkp"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="kdTkp"
                  placeholder="-- Pilih Jenis Layanan --"
                  size="sm"
                  :class="{invalid: !checkValidasi('kd_tkp').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('kd_tkp').m}}</span>
              </b-col>
              <b-col v-if="isReg()" cols="4" sm="4" md="4" lg="4">
                <label for="no_antrian">No Antrian</label>
                <multiselect
                  id="no_antrian"
                  :class="{invalid: !checkValidasi('no_antrian').b}"
                  v-model="dataForm.no_antrian"
                  :options="listAntrian"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  :option-height="204"
                  label="nama_no_antrian"
                  track-by="antrian_id"
                  placeholder=""
                  :disabled="!dataForm.tanggal_daftar || !isReg()"
                  @select="pilihAntrian()"
                  @remove="pilihAntrian()"
                >
                  <template slot="singleLabel" slot-scope="props"><div style="" class="option__desc">{{ props.option.nama_no_antrian }}</div></template>
                </multiselect>
                <span class="text-danger">{{checkValidasi('no_antrian').m}}</span>
                <b-form-input 
                  v-if="dataForm.no_antrian"
                  cols="12"
                  id="no_antrian"
                  class="py-5 mt-2 text-center"
                  style="font-size: 30px;"
                  v-model="dataForm.no_antrian.nama_no_antrian"
                  disabled
                  size="lg"
                ></b-form-input>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12" class="mt-2" v-if="dataForm.no_rm">
                <b-row style="margin: 0px -8px;">
                  <b-col cols="4" sm="4" md="4" lg="4">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="dataForm.satu_sehat"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                    >
                      <h5>Satu Sehat</h5>
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="4" sm="4" md="4" lg="4" v-if="dataForm.satu_sehat == 0" >
                    <b-button
                      variant="primary"
                      @click="dataForm.satu_sehat = 1"
                      :disabled="busy"
                      >
                      Kirim data ke satu sehat
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-color-black"><strong>Data Pasien</strong></h5>
              </b-col>
            </b-row>
            <b-row style="row-gap: 10px;">
              <b-col cols="5" sm="5" md="5" lg="5">
                <label for="nama_lengkap">Nama Pasien</label>
                <b-form-input
                  id="nama_lengkap"
                  v-model="dataPasien.nama_lengkap"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="3" sm="3" md="3" lg="3">
                <label for="jenis_kelamin">Jenis Kelamin</label>
                <b-form-input
                  id="jenis_kelamin"
                  v-model="dataPasien.nama_jenis_kelamin"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="umur">Umur</label>
                <b-form-input
                  id="umur"
                  v-model="dataPasien.umur"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="nik">NIK</label>
                <b-form-input
                  id="nik"
                  v-model="dataPasien.nik"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="telepon">Telepon</label>
                <b-form-input
                  id="telepon"
                  v-model="dataPasien.no_telp"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="no_bpjs">No BPJS</label>
                <b-form-input
                  id="no_bpjs"
                  v-model="dataPasien.no_bpjs"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="alamat_sekarang">Alamat Sekarang</label>
                <b-form-input
                  id="alamat_sekarang"
                  v-model="dataPasien.alamat_sekarang"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="pekerjaan">Pekerjaan</label>
                <b-form-input
                  id="pekerjaan"
                  v-model="dataPasien.pekerjaan"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="nama_penjamin">Penanggung Jawab</label>
                <b-form-input
                  id="nama_penjamin"
                  v-model="dataPasien.nama_penjamin"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-color-black"><strong>INPUT DATA PASIEN</strong></h5>
              </b-col>
            </b-row>
            <b-row style="row-gap: 10px;">
              <b-col cols="6" sm="6" md="6" lg="6">
                <label for="golongan_pasien">Golongan Pasien <span class="text-danger">*</span></label>
                <multiselect
                  id="golongan_pasien"
                  v-model="dataForm.golongan_pasien"
                  :options="$store.state.data_static.golongan_pasien"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih Golongan Pasien --"
                  size="sm"
                  :class="{invalid: !checkValidasi('golongan_pasien').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('golongan_pasien').m}}</span>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6">
                <label for="kelas">Kelas <span class="text-danger" v-if="isAsuransiBPJS">*</span></label>
                <multiselect
                  id="kelas"
                  v-model="dataForm.kelas"
                  :options="listKelas"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_kelas"
                  track-by="ms_kelas_id"
                  placeholder="-- Pilih Kelas --"
                  size="sm"
                  :class="{invalid: !checkValidasi('kelas').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('kelas').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="nama_penjamin_pendaftaran">Nama Penjamin</label>
                <b-form-input
                  id="nama_penjamin_pendaftaran"
                  v-model="dataForm.nama_penjamin_pendaftaran"
                  :state="checkValidasi('nama_penjamin_pendaftaran').s"
                ></b-form-input>
                <span class="text-danger">{{checkValidasi('nama_penjamin_pendaftaran').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="no_telp_penjamin_pendaftaran">No Telp Penjamin</label>
                <b-form-input
                  id="no_telp_penjamin_pendaftaran"
                  v-model="dataForm.no_telp_penjamin_pendaftaran"
                  :state="checkValidasi('no_telp_penjamin_pendaftaran').s"
                ></b-form-input>
                <span class="text-danger">{{checkValidasi('no_telp_penjamin_pendaftaran').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="hubungan_penjamin_pendaftaran">Hubungan Penjamin</label>
                <b-form-input
                  id="hubungan_penjamin_pendaftaran"
                  v-model="dataForm.hubungan_penjamin_pendaftaran"
                  :state="checkValidasi('hubungan_penjamin_pendaftaran').s"
                ></b-form-input>
                <span class="text-danger">{{checkValidasi('hubungan_penjamin_pendaftaran').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="sistole">Sistole</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="sistole"
                    type="number"
                    v-model="dataForm.sistole"
                    :state="checkValidasi('sistole').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>mm</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('sistole').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="diastole">Diastole</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="diastole"
                    type="number"
                    v-model="dataForm.diastole"
                    :state="checkValidasi('diastole').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>Hg</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('diastole').m}}</span>
              </b-col>
              <!-- <b-col cols="4" sm="4" md="4" lg="4">
                <label for="rujuk_balik">Rujuk Balik <span class="text-danger">*</span></label>
                <b-form-input
                  id="rujuk_balik"
                  v-model="dataForm.rujuk_balik"
                  :state="checkValidasi('rujuk_balik').s"
                ></b-form-input>
                <span class="text-danger">{{checkValidasi('rujuk_balik').m}}</span>
              </b-col> -->
              <!-- {{dataForm.rujuk_balik}} -->
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="rujuk_balik">Rujuk Balik <span class="text-danger">*</span></label>
                <multiselect
                  id="rujuk_balik"
                  v-model="dataForm.rujuk_balik"
                  :options="listRujukBalik"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih Rujuk Balik --"
                  size="sm"
                  :class="{invalid: !checkValidasi('rujuk_balik').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('rujuk_balik').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="berat_badan">Berat Badan</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="berat_badan"
                    type="number"
                    v-model="dataForm.berat_badan"
                    :state="checkValidasi('berat_badan').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>kg</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('berat_badan').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="tinggi_badan">Tinggi Badan</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="tinggi_badan"
                    type="number"
                    v-model="dataForm.tinggi_badan"
                    :state="checkValidasi('tinggi_badan').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>cm</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('tinggi_badan').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="lingkar_perut">Lingkar Perut</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="lingkar_perut"
                    type="number"
                    v-model="dataForm.lingkar_perut"
                    :state="checkValidasi('lingkar_perut').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>cm</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('lingkar_perut').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="resp_rate">Resp Rate</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="resp_rate"
                    type="number"
                    v-model="dataForm.resp_rate"
                    :state="checkValidasi('resp_rate').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>/menit</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('resp_rate').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="heart_rate">Heart Rate</label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="heart_rate"
                    type="number"
                    v-model="dataForm.heart_rate"
                    :state="checkValidasi('heart_rate').s"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>/menit</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('heart_rate').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="kunjungan_sakit">Kunjungan Sakit <span class="text-danger">*</span></label>
                <b-form-checkbox
                  id="kunjungan_sakit"
                  v-model="dataForm.kunjungan_sakit"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  :state="checkValidasi('kunjungan_sakit').s"
                >
                  <p class="m-0 p-0">Sakit <span class="text-danger">*</span></p>
                  <!-- <p class="m-0 p-0">{{dataForm.kunjungan_sakit ? 'Sehat' : 'Sakit'}}</p> -->
                </b-form-checkbox>
                <span class="text-danger">{{checkValidasi('kunjungan_sakit').m}}</span>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12">
                <label for="keluhan">Keluhan <span class="text-danger">*</span></label>
                <b-form-textarea
                  id="keluhan"
                  v-model="dataForm.keluhan"
                  :state="checkValidasi('keluhan').s"
                ></b-form-textarea>
                <span class="text-danger">{{checkValidasi('keluhan').m}}</span>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-color-black"><strong>INPUT DATA LAINYA</strong></h5>
              </b-col>
            </b-row>
            <b-row style="row-gap: 10px">
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="tarif_puskesmas">Tarif Sesuai Puskesmas <span class="text-danger">*</span></label>
                <multiselect
                  id="tarif_puskesmas"
                  v-model="dataForm.tarif_puskesmas"
                  :options="listRujukBalik"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih Tarif Sesuai Puskesmas --"
                  size="sm"
                  :class="{invalid: !checkValidasi('tarif_puskesmas').b}"
                  @select="dataForm.tarif_puskesmas && dataForm.tarif_puskesmas.value == '1' ? dataForm.tarif_pendaftaran = $store.state.login.tarif_pendaftaran || 0 : dataForm.tarif_pendaftaran = 0"
                  @remove="dataForm.tarif_pendaftaran = 0"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('tarif_puskesmas').m}}</span>
              </b-col>
              <b-col cols="8" sm="8" md="8" lg="8">
                <label for="tarif_pendaftaran">Tarif Pendaftaran</label>
                <b-form-input
                  id="tarif_pendaftaran"
                  v-model="dataForm.tarif_pendaftaran"
                  :disabled="dataForm.tarif_puskesmas && dataForm.tarif_puskesmas.value == '1'" 
                  :state="checkValidasi('tarif_pendaftaran').s"
                ></b-form-input>
                  <!-- :disabled="dataForm.tarif_puskesmas && dataForm.tarif_puskesmas.value == '1'" -->
                <small class="text-danger">{{$numberFormat(dataForm.tarif_pendaftaran || 0, 'idr')}}</small>
                <span class="text-danger">{{checkValidasi('tarif_pendaftaran').m}}</span>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12" v-if="kodeLayanan == 'RJ'">
                <label for="poliklinik">Poliklinik <span class="text-danger">*</span></label>
                <multiselect
                  id="poliklinik"
                  v-model="dataForm.poliklinik"
                  :options="listPoliklinik"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="ms_poli_id"
                  placeholder="-- Pilih Poliklinik --"
                  size="sm"
                  :class="{invalid: !checkValidasi('poliklinik').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('poliklinik').m}}</span>
              </b-col>
              <b-col>
                <label for="asuransi">Asuransi <span class="text-danger">*</span></label>
                  <!-- :options="listAsuransi" -->
                <multiselect
                  id="asuransi"
                  v-model="dataForm.asuransi"
                  :options="$store.state.data_static.asuransi"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih Asuransi --"
                  size="sm"
                  :class="{invalid: !checkValidasi('asuransi').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('asuransi').m}}</span>
              </b-col>
              <b-col cols="2" sm="2" md="2" lg="2" v-if="isAsuransiBPJS">
                <label for="type_no_asuransi">Type No</label>
                <multiselect
                  id="type_no_asuransi"
                  v-model="dataForm.type_no_asuransi"
                  :options="listTypeNoAsuransi"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="Type"
                  size="sm"
                  :class="{invalid: !checkValidasi('type_no_asuransi').b}"
                  @input="dataForm.no_kartu = null"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('type_no_asuransi').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4" v-if="isAsuransiBPJS">
                <label for="no_kartu">No BPJS/Asuransi</label>
                <b-input-group prepend="" class="">
                  <b-form-input
                    id="no_kartu"
                    v-model="dataForm.no_kartu"
                    :state="checkValidasi('no_kartu').s"
                    :disabled="dataForm.asuransi == null || (isAsuransiBPJS && dataForm.type_no_asuransi == null) || isAsuransiUmum"
                    debounce="2000"
                    @keydown.enter="cekAsuransi()"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button 
                      variant="success" 
                      :disabled="dataForm.asuransi == null || (isAsuransiBPJS && dataForm.type_no_asuransi == null) || isAsuransiUmum || busy"
                      @click="cekAsuransi()"
                    >cek</b-button>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{checkValidasi('no_kartu').m}}</span>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6" v-if="isAsuransiBPJS">
                <label for="pst_prb">Diabetes</label>
                <b-form-input
                  id="pst_prb"
                  v-model="dataForm.pst_prb"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6" v-if="isAsuransiBPJS">
                <label for="pst_prol">Prolanis</label>
                <b-form-input
                  id="pst_prol"
                  v-model="dataForm.pst_prol"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6" v-if="isAsuransiBPJS">
                <label for="kd_provider_peserta">Faskes Pertama</label>
                <multiselect
                  id="kd_provider_peserta"
                  v-model="dataForm.kd_provider_peserta"
                  :options="listProviderPeserta"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nmProvider"
                  track-by="kdProvider"
                  placeholder="-- Pilih Faskes Pertama --"
                  size="sm"
                  :class="{invalid: !checkValidasi('kd_provider_peserta').b}"
                  disabled
                ></multiselect>
                <span class="text-danger">{{checkValidasi('kd_provider_peserta').m}}</span>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6" v-if="isAsuransiBPJS">
                <label for="jenis_peserta_bpjs">Jenis Peserta</label>
                <b-form-input
                  id="jenis_peserta_bpjs"
                  v-model="dataForm.jenis_peserta_bpjs"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-color-black"><strong>RUJUKAN</strong></h5>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6">
                <label for="jenis_rujukan">Jenis Rujukan</label>
                <multiselect
                  id="jenis_rujukan"
                  v-model="dataForm.jenis_rujukan"
                  :options="$store.state.data_static.jenis_rujukan"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih Jenis Rujukan--"
                  size="sm"
                  :class="{invalid: !checkValidasi('jenis_rujukan').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('jenis_rujukan').m}}</span>
              </b-col>
              <b-col cols="6" sm="6" md="6" lg="6">
                <label for="asal_rujukan">Asal Rujukan</label>
                <b-form-input
                  id="asal_rujukan"
                  v-model="dataForm.asal_rujukan"
                  :state="checkValidasi('asal_rujukan').s"
                ></b-form-input>
                <span class="text-danger">{{checkValidasi('asal_rujukan').m}}</span>
              </b-col>
            </b-row>

            <b-row class="mt-4" v-if="kodeLayanan == 'RI'">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-color-black"><strong>BED</strong></h5>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="ruang">Ruang <span class="text-danger">*</span></label>
                <multiselect
                  id="ruang"
                  v-model="dataForm.ruang"
                  :options="listRuang"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_ruang"
                  track-by="ms_ruang_id"
                  placeholder="-- Pilih Ruang --"
                  size="sm"
                  :class="{invalid: !checkValidasi('ruang').b}"
                  @input="getKamar(), dataForm.kamar = null, dataForm.bed = null"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('ruang').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="kamar">Kamar <span class="text-danger">*</span></label>
                <multiselect
                  id="kamar"
                  v-model="dataForm.kamar"
                  :options="listKamar"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_kamar"
                  track-by="ms_kamar_id"
                  placeholder="-- Pilih Kamar --"
                  size="sm"
                  :class="{invalid: !checkValidasi('kamar').b}"
                  @input="getBed(), dataForm.bed = null"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('kamar').m}}</span>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="bed">Bed <span class="text-danger">*</span></label>
                <multiselect
                  id="bed"
                  v-model="dataForm.bed"
                  :options="listBed"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_bed"
                  track-by="ms_bed_id"
                  placeholder="-- Pilih Bed --"
                  size="sm"
                  :class="{invalid: !checkValidasi('bed').b}"
                ></multiselect>
                <span class="text-danger">{{checkValidasi('bed').m}}</span>
              </b-col>
            </b-row>
            <b-row style="margin-top:20px" align-h="end">
              <b-col cols="auto">
                <PrintKartuBerobat 
                  :data_puskesmas="data_puskesmas"
                  :dataPasien="dataPasien"
                  :isReady="isReady"
                />
                <PrintGeneralConsent
                  :data_puskesmas="data_puskesmas"
                  :dataPasien="dataPasien"
                  :isReady="isReady"
                />
                <b-button
                  style="margin:10px"
                  @click="refresh()"
                  variant="danger"
                  :disabled="busy"
                  >Reset
                </b-button>
                <b-button
                  v-if="isReg()"
                  @click="save()"
                  variant="primary"
                  :disabled="!isValid || busy || alertnya"
                  >Simpan
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <modal-pendaftaran
      :listPoliklinik="listPoliklinik"
      @pilihPendaftaran="pilihPendaftaran"
    />
    <modal-search
      :keyModal="'modal-search-pendaftaran'"
      @pilihPasien="pilihPasien"
    />
  </div>
</template>
<script>
import ModalPendaftaran from './modal_pendaftaran.vue'
import ModalSearch from './modal_pasien.vue'
import PrintKartuBerobat from './print_kartu_berobat.vue'
import PrintGeneralConsent from './print_general_consent.vue'
import { required, numeric, decimal, minValue } from "vuelidate/lib/validators";
import useVuelidate from '@vuelidate/core';
import _ from "lodash"

export default {
  components: {
    ModalPendaftaran,
    ModalSearch,
    PrintKartuBerobat,
    PrintGeneralConsent,
  },
  setup() { return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) } },
  data() {
    return {
      busy: false,
      show: false,
      nik: null,
      niknya: null,
      dataPasien: {},
      dataForm: {
        satu_sehat: 1,
        no_antrian: null,
        antrian_loket_id: null,

        rm_id: null,
        kd_provider_peserta: null,
        kunjungan_sakit: true,
        tanggal_daftar: new Date(this.tanggal_sesi),
        no_kartu: null,
        keluhan: null,
        sistole: 0,
        diastole: 0,
        berat_badan: 0,
        tinggi_badan: 0,
        resp_rate: 0,
        lingkar_perut: 0,
        heart_rate: 0,
        // rujuk_balik: this.listRujukBalik[1],
        rujuk_balik: null,
        kd_tkp: this.$store.state.data_static.tkp[0],
        no_urut: null,
        type_no_asuransi: null,
        nama_penjamin_pendaftaran: null,
        no_telp_penjamin_pendaftaran: null,
        hubungan_penjamin_pendaftaran: null,
        jenis_rujukan: null,
        asal_rujukan: null,
        golongan_pasien: this.$store.state.data_static.golongan_pasien[0],
        ruang: null,
        kelas: null,
        kamar: null,
        bed: null,
        tarif_puskesmas: null,
        tarif_pendaftaran: 0,
        
        poliklinik: null,
        asuransi: null,
        kelas_pendaftaran: null,
        poli_layanan: 2,
      },
      alertnya: false,
      //list dropdown
      listRuang: [],
      listKelas: [],
      listKamar: [],
      listBed: [],
      listProviderPeserta: [],
      listJenisLayanan: [],
      listAsuransi: [],
      listPoliklinik: [],
      listLayanan: [],
      listTypeNoAsuransi: [],
      listAntrian: [],
      listRujukBalik: [ 
        { value: "1" , text: 'Ya'},
        { value: "0" , text: 'Tidak'},
      ],
      dataAntrian: null,
      dataSep: {},
      data_puskesmas: {},
    }
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.v$.dataForm.$invalid;
    },
    isDirty() {
      return this.v$.dataForm.$anyDirty;
    },
    isAsuransiBPJS(){
      return this.dataForm.asuransi && this.dataForm.asuransi.value === true
      // return this.dataForm.asuransi && this.dataForm.asuransi.tipe_asuransi == 2
    },
    isAsuransiUmum(){
      return this.dataForm.asuransi && this.dataForm.asuransi.value === false
      // return this.dataForm.asuransi && this.dataForm.asuransi.tipe_asuransi == 1
    }, 
    isReady() {
      return _.isEmpty(this.dataPasien) ? false : true
    },
    kodeLayanan(){
      return this.dataForm.kd_tkp && this.dataForm.kd_tkp.tipe_rawat ? this.dataForm.kd_tkp.tipe_rawat : null
    },
  },
  validations: {
    dataForm: {
      kd_provider_peserta: { 
        required: (value, sibiling, vm) => { return sibiling.asuransi && vm.isAsuransiBPJS ? value : true }, 
       },
      kunjungan_sakit: { 
        required ,
        validasiKunjunganSakit: (value, sibiling, vm) => { return vm.isAsuransiBPJS && sibiling.asuransi ? value : true },
      },
      tanggal_daftar: { required },
      keluhan: { required },
      sistole: { numeric, minValue: minValue(0) },
      diastole: { numeric, minValue: minValue(0) },
      berat_badan: { decimal, minValue: minValue(0) },
      tinggi_badan: { numeric, minValue: minValue(0) },
      resp_rate: { numeric, minValue: minValue(0) },
      lingkar_perut: { numeric, minValue: minValue(0) },
      heart_rate: { numeric, minValue: minValue(0) },
      rujuk_balik: { required },
      no_urut: {  },
      nama_penjamin_pendaftaran: {  },
      no_telp_penjamin_pendaftaran: {  },
      hubungan_penjamin_pendaftaran: {  },
      jenis_rujukan: {  },
      asal_rujukan: {  },
      golongan_pasien: { required },
      ruang: {  },
      kelas: { 
        required: (value, sibiling, vm) => { return sibiling.asuransi && vm.isAsuransiBPJS ? value : true }, 
      },
      kamar: {  },
      bed: {  },
      tarif_puskesmas: { required },
      tarif_pendaftaran: { 
        required: (value, sibiling) => { return sibiling.tarif_puskesmas && sibiling.tarif_puskesmas.value == '1' ? value : true },
       },
      
      rm_id: { required, },
      no_antrian: { 
        // required: (value, sibiling, vm) => { return vm.isReg() ? value : true }, 
        // validasiNoAntrian: (value, sibiling, vm) => { return vm.isReg() ? sibiling.status_no_antrian : true } 
      },
      asuransi: { required },
      type_no_asuransi: { 
        required: (value, sibiling, vm) => { return vm.isAsuransiBPJS && sibiling.asuransi ? value : true },
      },
      no_kartu: { 
        // required: (value, sibiling) => { return sibiling.asuransi && !((new RegExp(sibiling.asuransi.nama_asuransi)).test(/umum/i)) ? value : true }, 
        required: (value, sibiling, vm) => { return sibiling.asuransi && vm.isAsuransiBPJS ? value : true }, 
        validasiNoAsuransi: (value, sibiling, vm) => { return sibiling.asuransi && vm.isAsuransiBPJS ? sibiling.status_no_asuransi : true } 
      },
      poliklinik: { 
        // validasiPoliLayanan: (value, sibiling, vm) => { return vm.isReg() ? (sibiling.kd_tkp && sibiling.kd_tkp.tipe_rawat == 'RJ' ? ) || value : true },
        // validasiPoliLayanan: (value, sibiling, vm) => { return vm.isReg() ? sibiling.kd_tkp || value : true },
      },
      kd_tkp: { 
        validasiPoliLayanan: (value, sibiling, vm) => { return vm.isReg() ? sibiling.poliklinik || value : true },
      },
    },
  },
  watch: {
    tanggal_sesi(newVal, oldVal){
      if(newVal != oldVal){
        this.dataForm.tanggal_daftar = new Date(this.tanggal_sesi)
        // console.log(this.dataForm.tanggal_daftar)
      }
    },
    'dataPasien': {
      handler(data) {
        localStorage.setItem('dataPasien', JSON.stringify(data));
      },
      deep: true
    },
    'dataForm': {
      handler(data) {
        localStorage.setItem('dataForm', JSON.stringify({
          ...data,
          status_no_antrian: null,
          status_no_asuransi: null,
        }));
      },
      deep: true
    },
    'dataForm.tanggal_daftar'(newVar, oldVar){
      if(this.isReg()){
        this.dataSep.tanggal_daftar = newVar
        if(newVar != oldVar){
          if(newVar && this.$moment(oldVar).format('LL') != this.$moment(newVar).format('LL')) {
            if(oldVar != null){
              this.dataForm.no_antrian = null
            }
            this.getData()
            this.getListAntrian()
          }
        }
      }
    },
    'dataForm.asuransi'(newVar, oldVar){
      if(newVar != oldVar){
        if(this.isAsuransiBPJS && !this.dataForm.no_kartu && this.dataForm.no_jkn) this.dataForm.no_kartu = this.dataForm.no_jkn
        if(oldVar != null) {
          this.dataForm.no_kartu = null
          this.dataForm.type_no_asuransi = null
          this.dataForm.status_no_asuransi = false
          this.dataForm.kd_provider_peserta = null
        }
      }
    },
    'dataForm.no_jkn'(newVar, oldVar){
      if(newVar != oldVar && oldVar != null && !this.dataForm.no_kartu){
        if(this.isAsuransiBPJS) this.dataForm.no_kartu = this.dataForm.no_jkn
      }
    },
    isAsuransiBPJS(newVar, oldVar){
      if(newVar != oldVar && newVar){
        this.listTypeNoAsuransi = [{text: 'No BPJS', value: 'noka'}, {text: 'NIK', value: 'nik'}, ]
        if(this.dataForm.type_no_asuransi == null) this.dataForm.type_no_asuransi = this.listTypeNoAsuransi[0]
      }
    }
  },
  async activated() {
    const vm = this
    // console.log("localStorage.getItem('idp')", localStorage.getItem('idp'))
    vm.$socket.emit("joinRoom", localStorage.getItem('idp'));
    vm.$socket.off("refresh_admin_pendaftaran")
    vm.$socket.on("refresh_admin_pendaftaran", (data) => {
      console.log('refresh_admin_pendaftaran', data);
      vm.getListAntrian()
      // if(data.status == 200 && data.data.rm_id == vm.dataForm.rm_id){
      if(data.status == 200){
        vm.$store.commit("set_alert", { variant: "success", msg: "Berhasil Registrasi Pendaftaran", showing: true });
        vm.busy = false
        vm.refresh()
      }else{
        vm.$store.commit("set_alert", { variant: "warning", msg: data.message.toUpperCase(), showing: true });
      }
    });
    vm.$socket.off("error")
    vm.$socket.on("error", (data) => {
      console.log('socket error', data);
      if(data.message == 'gagal'){
        vm.$store.commit("set_alert", { variant: "danger", msg: 'terjadi kesalahan pada sistem', showing: true });
      }else {
        vm.$store.commit("set_alert", { variant: "danger", msg: `${data.message}. Silahkan hubungi staff yang bertugas.`, showing: true });
      }
    })
    vm.$socket.off("refresh_admin")
    vm.$socket.on("refresh_admin", (data) => {
      // console.log('refresh_admin', data)
      if(data.status == 200){
        vm.getListAntrian()
      }
    });
    vm.busy = true
    vm.getData();
    vm.getJadwal();
    const local = JSON.parse(localStorage.getItem('dataPasien')) || {};
    if(vm.$store.state.puskesmas_id == local.puskesmas_id){
      vm.dataPasien = local
      // console.log('dataPasien', vm.dataPasien)
      const dataForm = JSON.parse(localStorage.getItem('dataForm'));
      if(dataForm) {
        // vm.dataForm.tanggal_daftar = null
        // vm.refresh()
        await Object.assign(
          vm.dataForm, 
          dataForm, 
          {
            rujuk_balik: vm.listRujukBalik[1],
            tarif_puskesmas: vm.listRujukBalik[1],
            tarif_pendaftaran: 0,
            tanggal_daftar: new Date(this.tanggal_sesi),
            status_no_antrian: true,
            status_no_asuransi: null,
          }
        );
      }
    }
    vm.getDataPuskesmas()
    vm.busy = false
    vm.v$.$reset()
  },
  methods: {
    async test(){
      // console.log('test')
    },
    isReg(){
      return this.dataForm && this.dataForm.pendaftaran_id ? false : true
    }, 
    pilihAntrian(){
      const vm = this
      // console.log(vm.dataForm.no_antrian)
      if(vm.dataForm.no_antrian) {
        vm.dataForm.poliklinik = vm.$findKey(vm.listPoliklinik, vm.dataForm.no_antrian.ms_poli_id, true, 'ms_poli_id')
      }else {
        vm.dataForm.poliklinik = null
      }
    },
    async getData(){
      const vm = this
      //jenis layanan
      vm.dataForm.jenis_layanan = vm.$store.state.data_static.tkp[0]
      //asuransi
      let listAsuransi = await vm.$axios.post("/ms_asuransi/list");
      vm.listAsuransi = listAsuransi.data.status == 200 ? listAsuransi.data.data.map( x => {
        return {
          ...x,
          text: x.nama_asuransi + ' - ' + vm.$findKey(vm.$store.state.data_static.tipe_asuransi, x.tipe_asuransi, false, 'value', 'text')
        }
      }) : []
      //Kelas
      let listKelas = await vm.$axios.post("/ms_kelas/list");
      vm.listKelas = listKelas.data.status == 200 ? listKelas.data.data : []
      //Ruang
      let listRuang = await vm.$axios.post("/ms_ruang/list");
      vm.listRuang = listRuang.data.status == 200 ? listRuang.data.data : []
      //provider / faskes
      let listProviderPeserta = await vm.$axios.post("/provider/list");
      vm.listProviderPeserta = listProviderPeserta.data.status == 200 ? listProviderPeserta.data.data : []
      vm.v$.$reset()
      vm.getListAntrian()
    },
    async getJadwal(){
      const vm = this
      //Poliklinik
      // let listPoliklinik = await vm.$axios.post("/ms_poli/list", {is_lab: 'false'});
      let listPoliklinik = await vm.$axios.post("/jadwal/list", {
        status_jadwal: '1',
        is_lab: 'false',
        tanggal_jadwal: vm.dataForm.tanggal_daftar == 'Invalid date' ? vm.$moment(vm.dataForm.tanggal_daftar).format('YYYY-MM-DD') : vm.$moment().format('YYYY-MM-DD'),
      });
      vm.listPoliklinik = []
      if(listPoliklinik.data.status == 200){
        for (let i = 0; i < listPoliklinik.data.data.length; i++) {
          const x = listPoliklinik.data.data[i];
          // if((vm.dataPasien.jenis_kelamin == 'P') || (x.kode_poli_bpjs != '003' && x.kode_poli_bpjs != '008')){
            vm.listPoliklinik.push({
              ...x,
              text: `${x.nama_poli}`
              // text: `${x.nama_poli}, ${vm.$moment(x.waktu_mulai, 'HH:mm:ss').format('HH:mm')} - ${vm.$moment(x.waktu_selesai, 'HH:mm:ss').format('HH:mm')}`
            })
          // }
        }
      }
    },
    async getKamar(){
      const vm = this
      let listKamar = await vm.$axios.post("/ms_kamar/list", {
        ms_ruang_id: vm.dataForm.ruang ? vm.dataForm.ruang.ms_ruang_id : undefined,
      });
      vm.listKamar = listKamar.data.status == 200 ? listKamar.data.data : []
    },
    async getBed(){
      const vm = this
      let listBed = await vm.$axios.post("/ms_bed/list", {
        ms_ruang_id: vm.dataForm.ruang ? vm.dataForm.ruang.ms_ruang_id : undefined, 
        ms_kamar_id: vm.dataForm.kamar ? vm.dataForm.kamar.ms_kamar_id : undefined,
      });
      vm.listBed = listBed.data.status == 200 ? listBed.data.data : []
    },
    async getListAntrian(){
      const vm = this
      let listAntrian = await vm.$axios.post("/antrian/list", {
        status_antrian: 2,
        tanggal_antrian: vm.dataForm.tanggal_daftar == 'Invalid date' ? vm.$moment(vm.dataForm.tanggal_daftar).format('YYYY-MM-DD') : vm.$moment().format('YYYY-MM-DD'),
        // poli_layanan: '1', 
        // status_antrian: '1',
      });
      // console.log('listAntrian', listAntrian)
      vm.listAntrian = listAntrian.data.status == 200 ? listAntrian.data.data.map(x => { return {
        ...x, 
        nama_no_antrian: x.initial + x.nomor_antrian,
      }}) : []
      vm.dataForm.no_antrian = null
    },
    async cekAsuransi(){
      const vm = this
      vm.busy = true
      vm.dataForm.status_no_asuransi = false
      vm.dataForm.pst_prol = null
      vm.dataForm.pst_prb = null
      vm.dataForm.jenis_peserta_bpjs = null
      vm.dataForm.is_pbi = null
      vm.dataForm.jenis_upah = null
      if(this.dataForm.no_kartu){
        if(vm.isAsuransiBPJS){
          vm.dataForm.kd_provider_peserta = null
          const res = await vm.$axios.post('/integrasi_pcare/get_peserta_by_jenis_kartu', {nomor_kartu: vm.dataForm.no_kartu, jenis_kartu: vm.dataForm.type_no_asuransi.value})
          // console.log('res', res)
          if(res.data.status == 200){
            vm.dataForm.no_kartu = res.data.data[0].noKartu
            vm.dataForm.type_no_asuransi = vm.$store.state.data_static.type_no_asuransi[0]
            vm.dataForm.pst_prol = res.data.data[0].pstProl
            vm.dataForm.pst_prb = res.data.data[0].pstPrb
            if(res.data.data[0].jnsPeserta){
              vm.dataForm.jenis_peserta_bpjs  = res.data.data[0].jnsPeserta.nama
              if(res.data.data[0].jnsPeserta.nama.toLowerCase().includes('pbi')){
                vm.dataForm.is_pbi  = true
              }else {
                vm.dataForm.is_pbi = false
              }
            }
            if(res.data.data[0].ketAktif == "AKTIF"){
              vm.$store.commit("set_alert", { variant: "success", msg: 'NO BPJS ' + res.data.data[0].ketAktif, showing: true });
            }else{
              vm.$store.commit("set_alert", { variant: "warning", msg: 'NO BPJS ' + res.data.data[0].ketAktif, showing: true });
            }
            vm.dataForm.kd_provider_peserta = res.data.data[0].kdProviderPst
            vm.dataForm.status_no_asuransi = true
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message, showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'cek asuransi selain bpjs belum dibuat', showing: true });
          vm.dataForm.status_no_asuransi = true
        }
      }else{
        // vm.$store.commit("set_alert", { variant: "danger", msg: 'Data Tidak Valid', showing: true });
      }
      vm.v$.dataForm.no_kartu.$reset();
      vm.v$.dataForm.no_kartu.$touch();
      vm.busy = false
    },
    async pilihPasien(data){
      const vm = this
      await vm.refresh()
      // console.log('data', data)
      if(data.nik == null && data.tanggal_lahir == null){
        vm.alertnya = true
      }else {
        vm.alertnya = false
      }
      data.nama_jenis_kelamin = data.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' 
      let expired = data.tanggal_lahir ? vm.$moment(data.tanggal_lahir) : vm.$moment()
      let now = vm.$moment()
      let hasil = vm.$moment.duration(now.diff(expired))
      data.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`
      vm.dataPasien = data
      //otomatisasi golongan pasien
      const umurTahun = hasil.years()
      if(umurTahun < 3){
        vm.dataForm.golongan_pasien = vm.$findKey(vm.$store.state.data_static.golongan_pasien, 'bayi', true)
      }else if(umurTahun < 19){
        vm.dataForm.golongan_pasien = vm.$findKey(vm.$store.state.data_static.golongan_pasien, 'anak', true)
      }else{
        vm.dataForm.golongan_pasien = vm.$findKey(vm.$store.state.data_static.golongan_pasien, 'dewasa', true)
      }
      vm.dataForm.no_rm = data.no_rm
      vm.dataForm.rm_id = data.rm_id
      vm.dataForm.nama_penjamin_pendaftaran = data.nama_penjamin
      vm.dataForm.no_telp_penjamin_pendaftaran = data.no_telp_penjamin
      vm.dataForm.hubungan_penjamin_pendaftaran = data.hubungan_penjamin
      if(data.no_bpjs){ 
        vm.dataForm.asuransi = vm.$store.state.data_static.asuransi[1]
        vm.dataForm.type_no_asuransi = vm.$store.state.data_static.type_no_asuransi[0]
        await new Promise(resolve => setTimeout(resolve, 100));
        vm.dataForm.no_kartu = data.no_bpjs
        if(!data.pendaftaran_id) await vm.cekAsuransi()
      }
      vm.getJadwal()
      vm.v$.$reset()
    },
    async pilihPendaftaran(x){
      const vm = this
      vm.busy = true
      await vm.refresh()
      await vm.pilihPasien(x)
      vm.dataForm = {
        kd_provider_peserta: vm.$findKey(vm.listProviderPeserta, x.kd_provider_peserta, true, 'kdProvider'),
        tanggal_daftar: new Date(x.tanggal_daftar),
        no_kartu: x.no_kartu,
        type_no_asuransi: x.no_kartu ? vm.$store.state.data_static.type_no_asuransi[0] : null,
        keluhan: x.keluhan,
        kunjungan_sakit: x.kunjungan_sakit,
        sistole: x.sistole,
        diastole: x.diastole,
        berat_badan: x.berat_badan,
        tinggi_badan: x.tinggi_badan,
        resp_rate: x.resp_rate,
        lingkar_perut: x.lingkar_perut,
        heart_rate: x.heart_rate,
        rujuk_balik: vm.$findKey(vm.listRujukBalik, x.rujuk_balik, true),
        // rujuk_balik: x.rujuk_balik,
        kd_tkp: vm.$findKey(vm.$store.state.data_static.tkp, x.kd_tkp, true, 'kdTkp'),
        no_urut: x.no_urut,
        rm_id: x.rm_id,
        nama_penjamin_pendaftaran: x.nama_penjamin,
        no_telp_penjamin_pendaftaran: x.no_telp_penjamin,
        hubungan_penjamin_pendaftaran: x.hubungan_penjamin,
        jenis_rujukan: vm.$findKey(vm.$store.state.data_static.jenis_rujukan, x.jenis_rujukan, true),
        asal_rujukan: x.asal_rujukan,
        golongan_pasien: vm.$findKey(vm.$store.state.data_static.golongan_pasien, x.golongan_pasien, true),
        ruang: vm.$findKey(vm.listRuang, x.ms_ruang_id, true, 'ms_ruang_id'),
        kelas: vm.$findKey(vm.listKelas, x.ms_kelas_id, true, 'ms_kelas_id'),
        kamar: vm.$findKey(vm.listKamar, x.ms_kamar_id, true, 'ms_kamar_id'),
        bed: null,

        satu_sehat: 1,
        no_rm: x.no_rm,
        no_antrian: null,
        antrian_loket_id: null,
        poliklinik: vm.$findKey(vm.listPoliklinik, x.ms_poli_id, true, 'ms_poli_id'),
        asuransi: vm.$findKey(vm.$store.state.data_static.asuransi, x.no_kartu != null, true),
        kelas_pendaftaran: null,
        poli_layanan: 2,
        pendaftaran_id: x.pendaftaran_id,
      }
      await vm.getKamar()
      vm.dataForm.kamar = vm.$findKey(vm.listKamar, x.ms_kamar_id, true, 'ms_kamar_id')
      await vm.getBed()
      vm.dataForm.bed = vm.$findKey(vm.listBed, x.ms_bed_id, true, 'ms_bed_id')
      // await vm.cekAsuransi()
      vm.v$.$reset()
      vm.busy = false
    },
    async save(){
      const vm = this
      await this.cekAsuransi()
      await this.v$.dataForm.$touch();
      vm.busy = true
      // await new Promise(resolve => setTimeout(resolve, 5000));
      if (vm.isValid && vm.isDirty) {
        let x = vm.dataForm
        x = {
          ...x,
          tanggal_daftar: vm.$moment(x.tanggal_daftar),
          kd_tkp: x.kd_tkp.kdTkp,
          tipe_rawat: x.kd_tkp.tipe_rawat,
          kd_provider_peserta: x.kd_provider_peserta ? x.kd_provider_peserta.kdProvider : null,
          ms_kelas_id: x.kelas ? x.kelas.ms_kelas_id : null,
          golongan_pasien: x.golongan_pasien.value,
          rujuk_balik: x.rujuk_balik ? x.rujuk_balik.value : null,
          jenis_rujukan: x.jenis_rujukan ? x.jenis_rujukan.value : null,
          ms_ruang_id: x.ruang ? x.ruang.ms_ruang_id : null,
          ms_kamar_id: x.kamar ? x.kamar.ms_kamar_id : null,
          ms_bed_id: x.bed ? x.bed.ms_bed_id : null,
          puskesmas_id: vm.$store.state.puskesmas_id,
          petugas_pendaftaran_id: vm.$store.state.login.id,
          room_id: localStorage.getItem('idp'),
          jumlah_tagihan_pendaftaran: x.tarif_pendaftaran,
          sistole: x.sistole ? x.sistole : 0,
          diastole: x.diastole ? x.diastole : 0,
          berat_badan: x.berat_badan ? x.berat_badan : 0,
          tinggi_badan: x.tinggi_badan ? x.tinggi_badan : 0,
          resp_rate: x.resp_rate ? x.resp_rate : 0,
          lingkar_perut: x.lingkar_perut ? x.lingkar_perut : 0,
          heart_rate: x.heart_rate ? x.heart_rate : 0,          
        }
        if(x.poliklinik) {
          x.ms_poli_id = x.poliklinik.ms_poli_id
          x.jadwal_id = x.poliklinik.jadwal_id
          x.initial = x.poliklinik.kode_poli_puskesmas
        }
        //antrian
        if(x.no_antrian){
          x.antrian_id = x.no_antrian.antrian_id
          x.tanggal_antrian = x.no_antrian.tanggal_antrian
          // x.initial = x.no_antrian.initial
          x.jadwal_id = x.no_antrian.jadwal_id
          x.ms_loket_id = x.no_antrian.ms_loket_id
          x.nomor_antrian = x.no_antrian.nomor_antrian
        }
        // console.log("localStorage.getItem('idp')", localStorage.getItem('idp'))
        if(x.tipe_rawat == 'RJ'){
          console.log('register_pendaftaran_rajal', x)
          this.$socket.emit(
            "register_pendaftaran_rajal", {
              ...x,
             }
          );
        }else if(x.tipe_rawat == 'RI'){
          console.log('register_pendaftaran_ranap', x)
          this.$socket.emit(
            "register_pendaftaran_ranap", {
              ...x,
             }
          );
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: "Fungsi Pendaftaran Ini Belum Dibuat", showing: true });
          vm.busy = false
        }
      }else{
        vm.$store.commit("set_alert", { variant: "warning", msg: "Data Tidak Valid", showing: true });
        vm.busy = false
      }
    },
    async refresh(){
      const vm = this
      vm.busy = true
      // console.log('refresh')
      vm.dataPasien = {}
      vm.dataForm = {
        satu_sehat: 1,
        no_antrian: null,
        antrian_loket_id: null,

        rm_id: null,
        kd_provider_peserta: null,
        kunjungan_sakit: true,
        tanggal_daftar: new Date(this.tanggal_sesi),
        no_kartu: null,
        keluhan: null,
        sistole: 0,
        diastole: 0,
        berat_badan: 0,
        tinggi_badan: 0,
        resp_rate: 0,
        lingkar_perut: 0,
        heart_rate: 0,
        tarif_puskesmas: this.listRujukBalik[1],
        tarif_pendaftaran: 0,
        rujuk_balik: this.listRujukBalik[1],
        // rujuk_balik: null,
        kd_tkp: this.$store.state.data_static.tkp[0],
        no_urut: null,
        type_no_asuransi: null,
        nama_penjamin_pendaftaran: null,
        no_telp_penjamin_pendaftaran: null,
        hubungan_penjamin_pendaftaran: null,
        jenis_rujukan: null,
        asal_rujukan: null,
        golongan_pasien: this.$store.state.data_static.golongan_pasien[0],
        ruang: null,
        kelas: null,
        kamar: null,
        bed: null,
        
        poliklinik: null,
        asuransi: null,
        kelas_pendaftaran: null,
        poli_layanan: 2,
      }
      this.getListAntrian()
      vm.v$.$reset()
      vm.busy = false
      // console.log('vm.dataForm', vm.dataForm)
    },
    checkValidasi(fieldName) {
      // console.log(fieldName)
      const field = this.v$.dataForm[fieldName];
      const x = field.$error === true && field.$errors.length ? field.$errors[0].$validator : '' ;
      if (x == 'required') {
        return { b: false, s: false, m: '* Data wajib diisi' }
      } else if (x == 'email') {
        return { b: false, s: false, m: '* Data harus berbentuk email' }
      } else if (x == 'numeric') {
        return { b: false, s: false, m: '* Data harus terdiri hanya dari angka' }
      } else if (x == 'minLength') {
        return { b: false, s: false, m: `* Data belum mencapai minimal digits` }
      } else if (x == 'maxLength') {
        return { b: false, s: false, m: `* Data melebihi maksimal digits` }
      } else if (x == 'alphaNum') {
        return { b: false, s: false, m: `* Data tidak boleh mengandung spasi atau simbol` }
      } else if (x == 'official') {
        return { b: false, s: false, m: `* Data tidak boleh mengandung spasi atau simbol` }
      } else if (x == 'validasiNoKontrolRujukan'){
        return { b: false, s: false, m: '* Isi no rujukan / no kontrol' }
      } else if (x == 'validasiPoliLayanan'){
        return { b: false, s: false, m: '* Isi Layanan / Poliklinik' }
      } else if (x == 'validasiNoAsuransi'){
        return { b: false, s: false, m: '* No Asuransi Tidak Valid' }
      } else if (x == 'validasiNoAntrian'){
        return { b: false, s: false, m: '* No Antrian Tidak Valid' }
      } else if (x == 'validasiNoRujukan'){
        return { b: false, s: false, m: '* No Rujukan Tidak Valid' }
      } else if (x == 'validasiNoKontrol'){
        return { b: false, s: false, m: '* No Kontrol Tidak Valid' }
      } else if (x == 'kuotaDokter'){
        return { b: false, s: false, m: '* Kuota Habis' }
      } else if (x == 'validasiKunjunganSakit'){
        return { b: false, s: false, m: '* Jika BPJS Harus Sakit' }
      } else {
        return { b: true, s: null, m: null }
      }
    },
    async getDataPuskesmas() {
      const vm = this
      let res = await vm.$axios('/puskesmas/details_by_id/' + vm.$store.state.puskesmas_id)
      // console.log('puskesmas', res.data.data);
      vm.data_puskesmas = res.data.status == 200 ? res.data.data[0] : {}
    },
  },
}
</script>

<style scoped>
#page-pendaftaran .btn-primary {
  background-color: #9c4098;
}
  /* .custom-title-color-black{
    font-weight: 700;
    font-size: 16px;
    color: #D52F65;
    } */
</style>
