<template>
  <span>
    <b-button
      class="ml-2"
      @click="printGeneralConsent('general_consent')"
      variant="success"
      :disabled="!data_puskesmas.id && !dataPasien.rm_id"
      >Persetujuan Umum
    </b-button>
    <!-- PRINT GENERAL CONSENT -->
    <div style="display: none;" id="general_consent">
      <section class="content-print A4">
        <div class="sheet">
          <div v-if="isReady">
            <div class="w-100">
              <table border="0" class="custom-table">
                <tbody>
                    <tr>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img :src="logo" alt="" style="width: 100%;"></td>
                        <td style="width: 70%;vertical-align: middle;">
                          <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;">PEMERINTAH <span>{{ $store.state.puskesmas.nama_kota }}</span> </h6>
                          <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;"><span>DINAS KESEHATAN</span></h6>
                          <h6 style="text-align: center;font-weight: bold;margin-bottom: 6px;font-size: 16pt;text-transform: uppercase;"> <span>{{ data_puskesmas.nama_puskesmas }}</span> </h6>
                          <h6 style="text-align: center;font-weight: normal;font-size: 10pt;"><span>{{ data_puskesmas.alamat_puskesmas }}</span></h6>
                        </td>
                        <td style="width: 15%;vertical-align: middle;padding-left: 1.5mm;padding-right: 1.5mm;"><img src="../../cetak/puskesmas-logo.png" alt="" style="width: 100%;"></td>
                    </tr>
                </tbody>
              </table>
              <div class="line" style="margin: 2.5mm 0 2.5mm 0"></div>
            </div>

            <div class="w-100" style="margin-top: 5mm;margin-bottom: 5mm;">
              <h6 style="font-size: 16pt;font-weight: bold;text-align: center;"><u>PERSETUJUAN UMUM <i>(GENERAL CONSENT)</i></u></h6>
            </div>

            <div class="w-100">
              <table border="0" class="custom-table" v-if="dataPasien">
                <tbody>
                  <tr>
                    <td colspan="3" style="font-weight: bold;">Saya yang bertanda tangan di bawah ini :</td>
                  </tr>
                  <tr>
                    <td style="width: 75mm;">Nama</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ dataPasien.nama_lengkap }}</span></td>
                  </tr>

                  <tr>
                    <td>Nomor Induk Kependudukan</td>
                    <td>:</td>
                    <td><span>{{ dataPasien.nik }}</span></td>
                  </tr>

                  <tr>
                    <td>Tanggal Lahir/Umur</td>
                    <td>:</td>
                    <td>
                      <span>
                        {{ $moment(dataPasien.tanggal_lahir).format('DD MMMM YYYY') }} / {{ dataPasien.umur }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td><span>{{ dataPasien.alamat_sekarang }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table">
                <tbody>
                  <tr>
                    <td colspan="3" style="font-weight: bold;">Sebagai Penanggung Jawab bagi saya sendiri :</td>
                  </tr>
                  <tr>
                    <td style="width: 75mm;">Nama</td>
                    <td class="autowidth">:</td>
                    <td><span>{{ dataPasien.nama_lengkap }}</span></td>
                  </tr>

                  <tr>
                    <td>Nomor Induk Kependudukan</td>
                    <td>:</td>
                    <td><span>{{ dataPasien.nik }}</span></td>
                  </tr>

                  <tr>
                    <td>Tanggal Lahir/Umur</td>
                    <td>:</td>
                    <td>
                      <span>
                        {{ $moment(dataPasien.tanggal_lahir).format('DD MMMM YYYY') }} / {{ dataPasien.umur }}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td><span>{{ dataPasien.alamat_sekarang }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="margin-top: 5mm;width: 100%;">
              <table border="1" class="custom-table" style="font-size: 12pt;">
                <thead>
                  <tr>
                    <th class="autowidth">No.</th>
                    <th>MATERI PENJELASAN</th>
                    <th>ISI PENJELASAN</th>
                    <th>SETUJU/TIDAK</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td rowspan="4">1.</td>
                    <td rowspan="4">PERSETUJUAN UNTUK PENGOBATAN</td>
                    <td style="text-align: justify;">
                      Bersedia mentaati peraturan dan tata tertib yang berlaku di <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> serta memenuhi keseluruhan hak dan kewajiban pasien dan mengerti tentang alur pelayanan <span>{{ $store.state.puskesmas.nama_puskesmas }}</span>.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style="text-align: justify;">
                      Memberikan kuasa kepada tenaga kesehatan (dokter, perawat, bidan dan tenaga kesehatan lainnya) yang melakukan asuhan perawatan untuk tindakan pemeriksaan fisik, melakukan prosedur diagnostik dan tatalaksana pelayanan kesehatan sesuai dengan pertimbangan dokter, yang diperlukan atau disarankan pada perawatan saya. Hal ini mencakup seluruh pemeriksaan, prosedur diagnostik rutin dan pengambilan sampel darah serta cairan tubuh lainnya untuk pemeriksaan laboratorium yang diperlukan untuk proses pengobatan.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style="text-align: justify;">
                      Bersedia diberikan semua obat yang diresepkan oleh dokter yang diambil dari Ruang Farmasi <span>{{ $store.state.puskesmas.nama_puskesmas }}</span>.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style="text-align: justify;">
                      <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> tidak bertanggung jawab terhadap kehilangan, kerusakan atau pencurian barang bagi pasien yang tidak menjaga atau melindungi barang miliknya sendiri, maka dari itu tidak diperkenankan membawa barang berharga (perhiasan, elektronik dan barang berharga lainnya).
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="2">2.</td>
                    <td rowspan="2">PERSETUJUAN PELEPASAN INFORMASI</td>
                    <td style="text-align: justify;">
                        Saya memahami informasi yang ada didalam diri saya, termasuk diagnostik, hasil laboratorium dan hasil tes diagnostik yang digunakan untuk perawatan medis, akan dijamin kerahasiannya oleh <span>{{ $store.state.puskesmas.nama_puskesmas }}</span>.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                        &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style="text-align: justify;">
                      Saya memberi wewenang kepada <span>{{ $store.state.puskesmas.nama_puskesmas }}</span> untuk memberikan informasi tentang rahasia kedokteran saya bila diperlukan untuk memproses klaim asuransi, perusahaan, dan atau lembaga pemerintah lainnya dan anggota keluarga saya yaitu :

                      <table border="1" class="custom-table" style="font-size: 12pt;">
                        <thead>
                          <tr>
                            <th class="autowidth">Nama</th>
                            <th class="w-100">Hubungan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                          </tr>

                          <tr>
                            <td>-</td>
                            <td>-</td>
                          </tr>

                          <tr>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td>3.</td>
                    <td>INFORMASI BIAYA</td>
                    <td style="text-align: justify;">
                      Membayar total biaya pengobatan dan perawatan yang diberikan sesuai dengan peraturan yang berlaku, apabila saya TIDAK termasuk sebagai peserta jaminan kesehatan yang   berlaku di <span>{{ $store.state.puskesmas.nama_puskesmas }}</span>.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td>4.</td>
                    <td>INFORMASI KRITIK DAN SARAN</td>
                    <td style="text-align: justify;">
                      Menerima informasi tentang adanya tata cara mengajukan dan mengatasi
                      keluhan  terkait  pelayanan  medis  yang  diberikan  kepada  diri  saya.  Saya
                      setuju untuk mengikuti tata cara mengajukan keluhan sesuai prosedur yang
                      berlaku.                            
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="2">5.</td>
                    <td rowspan="2">INFORMASI KRITIK DAN SARAN</td>
                    <td style="text-align: justify;">
                      Saya mengetahui dan menyetujui bahwa berdasarkan Peraturan Menteri
                      Kesehatan Nomor 2 Tahun 2022 tentang Rekam Medis, fasilitas kesehaatan
                      wajib membuka akses dan mengirim data rekam medis kepada Kementrian
                      Kesehatan melalui platform SATUSEHAT.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>
                  
                  <tr>
                    <td style="text-align: justify;">
                      Saya  mengetahui  untuk  menerima  dan  membuka  data  Pasien  dari
                      Fasilitas   Pelayanan   Kesehatan   lainnya   melalui   SATUSEHAT   untuk
                      kepentingan pelayanan kesehatan dan/atau rujukan.
                    </td>
                    <td style="vertical-align: middle;text-align: center;">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
              <h6 style="text-align: justify;">Setelah membaca dan mendapatkan penjelasan, maka dengan ini saya MENGERTI dan SEPENUHNYA dengan sadar MENYETUJUI setiap pernyataan yang terdapat pada formulir ini dan menandatangani dengan tanpa paksaan.</h6>
            </div>

            <div class="w-100" style="margin-top: 5mm;">
              <h6 style="text-align: right;"><span style="font-size: 12px;">{{ $store.state.puskesmas.nama_kota }}</span>, <span>{{ $moment().format('DD MMMM YYYY') }}</span></h6>
            </div>
            <div class="w-100" style="margin-top: 5mm;">
              <table border="0" class="custom-table" style="font-size: 12pt;">
                <tbody>
                  <tr>
                    <td style="width: 45%;">
                      <h6 style="text-align: center;">Petugas Pendaftaran</h6>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <h6 style="text-align: center;"><span>{{ $store.state.nama_user.toUpperCase() }}</span></h6>
                    </td>
                    <td style="width: 10%;"></td>
                    <td style="width: 45%;">
                      <h6 style="text-align: center;">Yang Membuat Pernyataan</h6>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <h6 style="text-align: center;"><span>{{ dataPasien.nama_lengkap.toUpperCase() }}</span></h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="w-100" style="border: 1px solid #333; display: none; position: relative;" id="general_consent">
      <button class="button" id="btn-print">Cetak</button>
      
    </div>
  </span>
</template>

<script>
export default {
  props: [
    'isReady',
    'data_puskesmas',
    'dataPasien',
  ],
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    async printGeneralConsent(val) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          '/style-paper-a4.css',
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: localStorage.getItem('nama_puskesmas'), // override the window title
      }
      // await this.$htmlToPaper(val, options);
      await this.$htmlToPaper(val, options);
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>